import Vue from 'vue'
import VueRouter from 'vue-router'
import Event from '@/tools/events'

/*import Connection from "@/views/Connection"
import Home from "@/views/interface/Home"
import DashboardList from './views/dashboard/List'*/

Vue.use(VueRouter)



const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '',
			component: () => import('./layouts/Base'),
			children: [
				{
					path: '',
					component: () => import('./layouts/login'),
					children: [
						{
							path: '/',
							name: 'Login',
							component: () => import('./views/login/LoginForm')
						},

						{
							path: '/create_account',
							name: 'CreateAccount',
							component: () => import('./views/login/CreateAccount')
						},
						{
							path: '/reset_password/:token',
							name: 'ResetPassword',
							component: () => import('./views/login/ResetPassword')
						},
						{
							path: '/first_password',
							name: 'ChangePasswordFirstLog',
							component: () => import('./views/login/ChangePasswordFirstLog')
						},
						{
							path: '/authtokeneditprofile/:token',
							name: 'AutoLoginProfile',
							component: () => import('./views/login/AutoLogin')

						},
						{
							path: '/authtokeneditroom/:token/:name',
							name: 'AutoLoginRoom',
							component: () => import('./views/login/AutoLogin')

						},
					]
				},
				{
					path: '',
					component: () => import('./layouts/default'),
					children: [
						{
							path: '/home',
							name: 'Home',
							component: () => import('./views/dashboard/List')
						},
						{
							path: '/user',
							name: 'User',
							component: () => import('./views/user/Profile'),

						},
						{
							path: '/room/:room_key',
							name: 'GeneralRoomEdit',
							component: () => import('./views/room/Base'),
						},
					]
				},
				{
					path: '/weblink',
					name: 'MeetingLink',
					props: route => ({
						query: route.query
					}),
					component: () => import('./views/meeting/Home')
				},
				{
					path: '/404',
					name: 'notFound',
					component: () => import('./views/404')
				}
			]
		}
	],

})

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && to.name !== 'CreateAccount' && to.name !== 'ChangePasswordFirstLog' && to.name !== 'ResetPassword' && to.name !=='notFound'
		&& to.name !== 'MeetingLink' && to.name !== 'AutoLoginProfile'&& to.name !== 'AutoLoginRoom' && !Vue.prototype.$session.has('token')) {
		next({name: 'Login'})
	} else {
		Event.$root.$emit('routeUpdated');
		next()
	}
})

export default router
