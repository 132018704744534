import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from '../lang/en.json';
import fr from '../lang/fr.json'

Vue.use(VueI18n);
export const i18n = new VueI18n({
	locale: 'fr',
	fallbackLocale: 'en',
	messages: {en, fr}
})

export const $t = i18n.messages[i18n.locale];
