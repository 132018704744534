import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import VueSession from 'vue-session'

import vuetify from './plugins/vuetify'
import App from './App.vue'
import Events from './tools/events.js'
import routes from './router'
import _ from './tools/lodash.js'
import {i18n} from './plugins/i18n';
import './assets/poppins'
import '@mdi/font/css/materialdesignicons.min.css'

import DatetimePicker from 'vuetify-datetime-picker'
import { Cropper, Preview } from 'vue-advanced-cropper'

Vue.config.productionTip = false
// DEFINE PROTOTYPES

Vue.prototype.$event = Events;
Vue.prototype._ = _;
Vue.prototype.axios = axios

axios.defaults.baseURL = [process.env.VUE_APP_API, process.env.VUE_APP_API_PATH].join('/')


// DEFINE MODULE
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(DatetimePicker)
Vue.use(Cropper)
Vue.use(Preview)

Vue.use(VueSession, {persist: true})

// Set language
const language_navigator= navigator.language.split('-')[0]
if(Object.keys(i18n.messages).includes(language_navigator))
  i18n.locale=language_navigator
else i18n.locale='en'


new Vue({
  router: routes,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')





