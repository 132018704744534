<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>

export default {
  name: 'App'
};
</script>

<style lang="scss">


.v-application {
  font-family: Poppins,serif!important;

  /*overflow: hidden;*/
}

.v-application::-webkit-scrollbar {
  display: none;
}

</style>

