import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/src/styles/main.sass'

Vue.use(Vuetify, {
    icons: 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:  '#790346', // #E53935

            },
        },
    },
});
